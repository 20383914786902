import React from 'react';
import MainSection from '../ui/atom/main-section'
import styled from 'styled-components'

const Photo = styled.img`
  padding: 5px;
  border: 1px solid #ddd;
  width: 96%;
  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    width: 100%;
  }
`

const GoogleMap = styled.div`
  position: relative;
  padding-bottom: 75%; // This is the aspect ratio
  height: 0;
  overflow: hidden;

  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    display: none;
  }
`

const MapIFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
`

const ContactDetails = styled.ul`
  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    display: none;
  }
`

const Contact = () => {
	return (
    <MainSection>
      <h1>Contact Us</h1>
      <p>You are welcome to contact us through the telephone, or visit us on site.</p>
      <ContactDetails>
        <li>Telephone: 01223-412216</li>
        <li>Address: 53 Woodlark Road, Cambridge, CB3 0HT</li>
      </ContactDetails>
      <GoogleMap>
        <MapIFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6206.57973347321!2d0.09556868980746751!3d52.2218232110505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d870ce0d593b2f%3A0xc5cb98a6e639d86!2s53%20Woodlark%20Rd%2C%20Cambridge%20CB3%200HS!5e0!3m2!1sen!2suk!4v1732573178445!5m2!1sen!2suk" width="600" height="450" frameborder="0" style={{border: 0}} allowfullscreen></MapIFrame>
      </GoogleMap>
    </MainSection>
  )
}

export default Contact
